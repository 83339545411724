/**
 * @jsxRuntime classic
 * @jsx jsx
 */

import { type RefObject } from 'react';

import { css, jsx } from '@compiled/react';
import { useIntl } from 'react-intl-next';

import { IconButton } from '@atlaskit/button/new';
import CrossIcon from '@atlaskit/icon/core/migration/close--cross';
import SearchIcon from '@atlaskit/icon/core/search';
import { Box, xcss } from '@atlaskit/primitives';
import { N500 } from '@atlaskit/theme/colors';
import { token } from '@atlaskit/tokens';

// eslint-disable-next-line @atlassian/tangerine/import/no-restricted-paths
import { useIsVisualRefreshEnabled } from '../../../../controllers/store/bootstrap';
import { ApplicationModes } from '../../../constants/application-modes';
import { RovoIcon } from '../../../constants/icons/rovo';

import { LegacySearchIcon } from './legacy-search-icon';
import { messages } from './messages';

const textFieldBoxStyles = xcss({
	display: 'flex',
	alignItems: 'center',
	height: 'size.300',
	':focus': {
		height: 'size.400',
	},
	border: '1px solid',
	borderColor: 'color.border',
	backgroundColor: 'elevation.surface.overlay',
	borderRadius: '4px',
	paddingRight: 'space.200',
	width: '100%',
});

const textFieldBoxFocusedStyles = xcss({
	height: 'size.400',
	border: 'none',
	boxShadow: 'elevation.shadow.overlay',
	paddingRight: '0',
});

const inputStyles = css({
	outline: 'none',
	background: 'none',
	font: token('font.body'),
	color: token('color.text.subtlest'),
	border: 'none',
	width: '100%',
	padding: '0',
	overflow: 'hidden',
	textOverflow: 'ellipsis',
	whiteSpace: 'nowrap',

	'&::placeholder': {
		color: token('color.text.subtlest'),
	},
});

const inputFocusedStyles = css({
	color: token('color.text'),
});

const iconWrapperStyles = xcss({
	minWidth: 'size.300',
	minHeight: 'size.300',
	display: 'flex',
	justifyContent: 'center',
	alignItems: 'center',
	color: 'color.text.subtlest',
});

const iconWrapperFocusedStyles = xcss({
	marginInlineStart: 'space.200',
	marginInlineEnd: 'space.150',
	minWidth: 'size.200',
});

const clearIconStyles = xcss({
	marginRight: 'space.100',
});

// Nav3 style overrides
// Can be removed once all supported products use Nav4
const nav3StyleOverrides = {
	textFieldBoxStyles: xcss({
		borderColor: 'color.border.input',
		backgroundColor: 'color.background.input',
		borderRadius: '5px',
		width: '200px',
	}),
	textFieldBoxFocusedStyles: xcss({
		backgroundColor: 'elevation.surface.overlay',
		borderRadius: 'border.radius.200',
		width: '780px',
	}),
	iconWrapperStyles: xcss({
		minWidth: '30px',
	}),
	inputStyles: css({
		font: token('font.body'),
	}),
};

export type TextfieldProps = {
	value: string;
	dialogId: string;
	quickFindOpen: boolean;
	appMode?: ApplicationModes;
	isNav4Enabled?: boolean;
	isAdminHubAIEnabled?: boolean;
	onEnter?: (e: React.KeyboardEvent<HTMLInputElement>) => void;
	onFocus?: React.FocusEventHandler<HTMLInputElement>;
	onBlur?: React.FocusEventHandler<HTMLInputElement>;
	onSelect?: React.ReactEventHandler<HTMLInputElement>;
	onKeyDown?: React.KeyboardEventHandler<HTMLInputElement>;
	onChange?: React.ChangeEventHandler<HTMLInputElement>;
	onClear?: () => void;
	inputRef?: RefObject<HTMLInputElement>;
	clearIconRef?: RefObject<HTMLButtonElement>;
	placeholder?: string;
};

export const Textfield = ({
	value,
	dialogId,
	quickFindOpen,
	appMode,
	isNav4Enabled,
	onEnter,
	onFocus,
	onBlur,
	onSelect,
	onKeyDown,
	onChange,
	onClear,
	inputRef,
	clearIconRef,
	placeholder,
}: TextfieldProps) => {
	const intl = useIntl();
	const [showVisualRefresh] = useIsVisualRefreshEnabled();

	const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
		if (!e.defaultPrevented && e.key === 'Enter') {
			e.preventDefault();
			onEnter && onEnter(e);
		}
		if (typeof onKeyDown === 'function') {
			onKeyDown(e);
		}
	};

	return (
		<Box
			xcss={[
				textFieldBoxStyles,
				quickFindOpen && textFieldBoxFocusedStyles,
				!isNav4Enabled && nav3StyleOverrides.textFieldBoxStyles,
				!isNav4Enabled && quickFindOpen && nav3StyleOverrides.textFieldBoxFocusedStyles,
			]}
			onClick={() => {
				inputRef?.current?.focus();
			}}
			aria-controls={quickFindOpen ? dialogId : undefined}
			aria-expanded={quickFindOpen}
			aria-haspopup="true"
		>
			<Box
				xcss={[
					iconWrapperStyles,
					quickFindOpen && iconWrapperFocusedStyles,
					!isNav4Enabled && nav3StyleOverrides.iconWrapperStyles,
				]}
			>
				{quickFindOpen && appMode === ApplicationModes.Rovo && showVisualRefresh ? (
					<RovoIcon />
				) : isNav4Enabled || quickFindOpen ? (
					<SearchIcon color="currentColor" label={intl.formatMessage(messages.searchIconLabel)} />
				) : (
					<LegacySearchIcon isExpanded={!!quickFindOpen} />
				)}
			</Box>
			<input
				ref={inputRef}
				css={[
					inputStyles,
					quickFindOpen && inputFocusedStyles,
					!isNav4Enabled && nav3StyleOverrides.inputStyles,
				]}
				onFocus={onFocus}
				onBlur={onBlur}
				onSelect={onSelect}
				onKeyDown={handleKeyDown}
				placeholder={placeholder}
				onChange={onChange}
				value={value}
				data-testid="search-input"
				autoComplete="off"
			/>
			{quickFindOpen && value && (
				<Box xcss={clearIconStyles}>
					<IconButton
						ref={clearIconRef}
						icon={(iconProps) => (
							<CrossIcon
								{...iconProps}
								color={token('color.icon', N500)}
								label={intl.formatMessage(messages.clearIconLabel)}
								LEGACY_size="small"
							/>
						)}
						spacing={'compact'}
						onClick={onClear}
						label={intl.formatMessage(messages.clearIconLabel)}
						appearance="subtle"
					/>
				</Box>
			)}
		</Box>
	);
};
