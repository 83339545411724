import { createContext, type Dispatch, type SetStateAction } from 'react';

import __noop from '@atlaskit/ds-lib/noop';

import type { SideNavState } from './types';

/**
 * Context for the visibility of the side nav.
 * State is initialised as null. This is used when the value has not been set yet - which is important to know for SSR.
 */
export const SideNavVisibilityState = createContext<SideNavState | null>(null);

/**
 * Sets the visibility of the side nav.
 */
export const SetSideNavVisibilityState =
	createContext<Dispatch<SetStateAction<SideNavState | null>>>(__noop);

/**
 * NOTE: This is outdated with the refactors in fg('platform_nav4_side_nav_flyout_animation') and will be cleaned up.
 *
 * Context for the visibility of the side nav on desktop screens.
 */
export const SideNavDesktopVisibility = createContext<boolean | null>(null);
/**
 * NOTE: This is outdated with the refactors in fg('platform_nav4_side_nav_flyout_animation') and will be cleaned up.
 *
 * Context for the visibility of the side nav on mobile screens.
 */
export const SideNavMobileVisibility = createContext<boolean>(false);

/**
 * NOTE: This is outdated with the refactors in fg('platform_nav4_side_nav_flyout_animation') and will be cleaned up.
 *
 * Sets the visibility of the side nav on desktop screens.
 * Internally exporting for use in tests.
 */
export const SetSideNavDesktopVisibility =
	createContext<Dispatch<SetStateAction<boolean | null>>>(__noop);
/**
 * NOTE: This is outdated with the refactors in fg('platform_nav4_side_nav_flyout_animation') and will be cleaned up.
 *
 * Sets the visibility of the side nav for mobile screens
 * Internally exporting for use in tests.
 */
export const SetSideNavMobileVisibility = createContext<Dispatch<SetStateAction<boolean>>>(__noop);
