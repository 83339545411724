import type { FC } from 'react';
import React, { memo, useState, useCallback } from 'react';
import { cssMap } from '@compiled/react';

import { token } from '@atlaskit/tokens';
import { N20A } from '@atlaskit/theme/colors';
import WorldIcon from '@atlaskit/icon/glyph/world';
import UserAvatarCircleIcon from '@atlaskit/icon/glyph/user-avatar-circle';

const spaceImgStyles = cssMap({
	default: {
		flexShrink: 0,
		border: 0,
		backgroundColor: token('color.skeleton', N20A),
	},
	loaded: {
		backgroundColor: 'transparent',
	},
});

const AvatarStyles = cssMap({
	xsmall: { borderRadius: '4px', width: '20px', height: '20px' },
	small: { borderRadius: '2px', width: '24px', height: '24px' },
	medium: { borderRadius: '3px', width: '32px', height: '32px' },
	large: { borderRadius: '3px', width: '40px', height: '40px' },
	xlarge: { borderRadius: '6px', width: '96px', height: '96px' },
});

export const types = {
	// eslint-disable-next-line @atlaskit/design-system/no-legacy-icons
	global: WorldIcon,
	// eslint-disable-next-line @atlaskit/design-system/no-legacy-icons
	personal: UserAvatarCircleIcon,
};

type Size = 'xsmall' | 'small' | 'medium' | 'large' | 'xlarge';

export interface SpaceIconProps {
	type?: string;
	label?: string;
	size?: Size;
	src?: string;
	testId?: string;
}

export const SpaceIcon: FC<SpaceIconProps> = memo(
	({ type, label, size = 'medium', src, testId }) => {
		const [imgLoaded, setImgLoaded] = useState(!src);
		const setLoaded = useCallback(() => {
			setImgLoaded(true);
		}, [setImgLoaded]);

		if (src) {
			return (
				<img
					css={[
						spaceImgStyles['default'],
						imgLoaded && spaceImgStyles['loaded'],
						AvatarStyles[size],
					]}
					src={src?.includes('/wiki') ? src : `/wiki${src}`}
					alt={label}
					onLoad={setLoaded}
					onError={setLoaded}
					data-testid={testId}
				/>
			);
		}

		// eslint-disable-next-line @atlaskit/design-system/no-legacy-icons
		const Icon: React.ElementType = (type && types[type as keyof typeof types]) || WorldIcon;

		return <Icon size={size} label={label} testId={testId} />;
	},
);
